<template>
  <v-container>
    <v-row align="start">
      <v-col cols="12" md="3">
        <div class="sticky-header">
          <h2 class="text-h4 font-weight-bold text-primary mb-4">
            Karya Kami untuk Bangsa
          </h2>

          <v-btn
            v-if="$vuetify.display.mdAndUp"
            variant="tonal"
            color="primary"
            class="mt-4"
            prepend-icon="mdi-arrow-right-circle"
            :href="'#dokumentasi-kegiatan'"
          >
            Lihat Dokumentasi
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" md="9">
        <v-expand-transition>
          <v-timeline
            side="end"
            align="start"
            :density="$vuetify.display.smAndDown ? 'compact' : 'default'"
          >
            <v-timeline-item
              v-for="(item, index) in workItems"
              :key="index"
              :dot-color="item.color"
              size="small"
            >
              <template v-slot:opposite></template>
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                  v-bind="props"
                  :class="['mb-6 pa-4']"
                  :variant="isHovering ? 'outlined' : 'flat'"
                >
                  <h3 class="mb-2 text-h6 font-weight-bold">
                    {{ item.title }}
                  </h3>
                  <p class="text-grey-darken-1 text-body-1">
                    {{ item.description }}
                  </p>

                  <v-expand-transition>
                    <div
                      v-if="item.details && isHovering"
                      class="mt-4 text-grey-darken-2"
                    >
                      <v-divider class="mb-3"></v-divider>
                      <p class="text-body-2">{{ item.details }}</p>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-hover>
            </v-timeline-item>
          </v-timeline>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
// Reactive data structure for our work items
const workItems = [
  {
    title: "Fasilitasi Kolaborasi",
    description:
      "Membangun platform yang menghubungkan individu dan komunitas untuk berkontribusi dalam berbagai kegiatan sosial.",
    color: "primary",
    details:
      "Mengembangkan ekosistem digital yang memungkinkan volunteer dan komunitas saling terhubung dengan mudah dan efisien.",
  },
  {
    title: "Dukung Inisiatif Sosial",
    description:
      "Menyediakan dukungan logistik dan sumber daya untuk kegiatan yang bermanfaat bagi masyarakat, seperti kebersihan tempat ibadah dan panti jompo.",
    color: "success",
    details:
      "Program dukungan kami telah membantu lebih dari 30 kegiatan sosial di seluruh Yogyakarta dengan sumber daya yang dibutuhkan.",
  },
  {
    title: "Berdayakan Pemuda",
    description:
      "Mendorong partisipasi aktif anak muda dalam aksi sosial, mengembangkan potensi dan kepemimpinan mereka.",
    color: "info",
    details:
      "Kami menyelenggarakan pelatihan kepemimpinan dan manajemen proyek sosial untuk para relawan muda di berbagai daerah.",
  },
  {
    title: "Ciptakan Kesempatan Karier",
    description:
      "Menawarkan peluang karier dan pencarian bakat berdasarkan keterlibatan sosial, menciptakan jaringan profesional yang peduli.",
    color: "warning",
    details:
      "Menghubungkan relawan berprestasi dengan perusahaan yang mencari kandidat dengan semangat sosial dan kepemimpinan yang terbukti.",
  },
  {
    title: "Bangun Kesadaran Sosial",
    description:
      "Meningkatkan kesadaran masyarakat akan isu-isu sosial dan pentingnya solidaritas melalui edukasi dan kampanye.",
    color: "error",
    details:
      "Kampanye awareness kami mencapai lebih dari 50.000 orang melalui media sosial dan kegiatan langsung di masyarakat.",
  },
];
</script>

<style scoped>
/* For larger screens - sticky title section */
@media (min-width: 960px) {
  .sticky-header {
    position: sticky;
    top: 100px;
  }
}

/* Smooth transitions for hover effects */
.v-card {
  transition: all 0.3s ease;
}

/* Enhance text readability */
p {
  line-height: 1.6;
}
</style>
